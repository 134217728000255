import React, { useRef, useState, useEffect } from 'react';
import { Modal, Divider, message } from 'antd';

// import StsUploadFile from '../../upload_files/sts_upload_file';
import { StsUploadImagesList } from '../../upload_files/sts_upload_files_list';
import { ChannelsRequestPut, ChannelsRequestDelete  } from './channels_requests';
import { CustomForm } from '../../base_components/form_component';

import {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum,
} from './channels_columns_label_config';

// 编辑 Channels

const UpdateChannels = ({ visible, setVisible, oldObj, reloadListCount, setReloadListCount }) => {

  const channelsImageObj = useRef({});
  const backgroundImgObj = useRef({});

  const [oldFormObj, setOldFormObj] = useState({});

  const [initFormCount, setInitFormCount] = useState(0);

  const [putRequestCount, setPutRequestCount] = useState(0);
  const reqObject = useRef({});
  const putResponseDataHandle = (respObj) => {
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      message.success('修改成功');
      setReloadListCount(reloadListCount + 1);
      setVisible(false);
    } else {
      message.error('网络错误...');
    }
  }

  const [deleteRequestCount, setDeleteRequestCount] = useState(0);
  const deleteChannels = () => {
    // 删除当前对象
    setDeleteRequestCount(deleteRequestCount + 1);
    setReloadListCount(reloadListCount + 1);
  };

  useEffect(() => {
    initImgAndForm();   // 初始化表格和图片
  }, [visible, oldObj]);

  const initImgAndForm = () => {
    // 初始化表格和图片
    setInitFormCount(initFormCount + 1);  // 初始化表格
    setOldFormObj({ ...oldObj });  // 给表格赋值

    // 生成图片列表
    // let channelsHomeImgs = [];
    // const logoImgKey = imageKeyNames[0];
    // const backgroundImgKey = imageKeyNames[1];

    // for (let index = 1; index < 10; index++) {
    //   const currentCosNameKey = imgKey + index;
    //   const currentUrlKey = currentCosNameKey + '_url';
    //   const currentCosName = oldObj[currentCosNameKey];
    //   const currentUrl = oldObj[currentUrlKey];
    //   if (typeof currentCosName === 'string' && currentCosName.length > 5) {
    //     channelsHomeImgs.push({
    //       cosNameKey: currentCosNameKey,
    //       cosName: currentCosName,
    //       urlKey: currentUrlKey,
    //       url: currentUrl
    //     });
    //   }
    // }
    // // console.log(channelsHomeImgs);
    // setOldChannelsHomeImages(channelsHomeImgs);   // 更新图片列表
  }

  const channelsLogoImageUploaded = (imagesObj) => {
    // logo图上传后操作
    channelsImageObj.current = imagesObj;
  }

  const channelsBackgroundImageUploaded = (imagesObj) => {
    // logo图上传后操作
    backgroundImgObj.current = imagesObj;
  }

  const onFinish = (values) => {
    // 提交表单
    let upObj = { ...values, ...channelsImageObj.current, ...backgroundImgObj.current };
    console.log(upObj);
    // 时间转时间戳
    // let newDate = new Date(upObj.end_time);
    // upObj.end_time = parseInt(newDate.getTime());    // 时间转时间戳
    // console.log(upObj.end_time);
    reqObject.current = upObj;
    setPutRequestCount(putRequestCount + 1);
  }

  return (
    <Modal visible={visible}
      title="Channels"
      closable={false}
      footer={null}
    >
      <div style={{width: '100%', height: 'auto', textAlign: 'center'}}>
        <h4>Logo</h4>
        <StsUploadImagesList
          maxImagesNum={maxImagesNum}
          imageKeyName={imageKeyNames[0]}
          oldObj={oldObj}
          onChange={channelsLogoImageUploaded}
        ></StsUploadImagesList>
      </div>
      <Divider></Divider>
      <div style={{width: '100%', height: 'auto', textAlign: 'center'}}>
      <h4>背景图片</h4>
        <StsUploadImagesList
          maxImagesNum={maxImagesNum}
          imageKeyName={imageKeyNames[1]}
          oldObj={oldObj}
          onChange={channelsBackgroundImageUploaded}
        ></StsUploadImagesList>
      </div>
      <Divider></Divider>

      <CustomForm
        columns={formKeys}
        disabledKeys={formDisabledKeys}
        notShowKeys={formNotShowKeys}
        formKeyLabelObj={formKeysLabelsObj}
        formKeySelectComponentsObj={formKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={() => setVisible(false)}
        showDeleteButton={true}
        deleteHandle={deleteChannels}
        updateFormObj={oldFormObj}
        formInitialObj={{}}
        initFormCount={initFormCount}
      ></CustomForm>

      <ChannelsRequestPut
        requestCount={putRequestCount}
        reqObject={reqObject.current}
        responseDataHandle={putResponseDataHandle}
        id={oldFormObj.id}></ChannelsRequestPut>
      <ChannelsRequestDelete
        requestCount={deleteRequestCount}
        responseDataHandle={(respObj) => setVisible(false)}
        id={oldFormObj.id}></ChannelsRequestDelete>
    </Modal>
  )
}

UpdateChannels.defaultProps = {
  visible: false,
  setVisible: (val) => console.log(val),
  oldObj: {}
}

export default UpdateChannels;
