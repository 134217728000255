import React from 'react';
// import { DatePicker } from 'antd';
import { CustomSelect } from '../../base_components/select_component';
import ListsItem from '../../base_components/list_item';

// Channels 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = [
  "id", "name", "front_desc", "front_name", "icon", "img", "is_category", "is_channel", "is_show",
  "keywords", "level", "p_height", "parent_id", "show_index", "sort_order", "add_time", "update_time", "explains"
];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = ["id", "front_name", "icon", "img", "is_category", "is_channel", "level"];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = [
  "front_name", "icon", "img", "is_category", "is_channel",
  "level", "show_index", "parent_id"
];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
  "id": "ID", "name": "名称", "front_desc": "频道简介", "front_name": "频道名称", "icon": "图标", "img": "背景图片", "is_category": "是分类",
  "is_channel": "是频道", "is_show": "显示", "keywords": "关键词", "level": "级别", "p_height": "背景图高", "parent_id": "上级ID",
  "show_index": "显示序号", "sort_order": "排序序号", "add_time": "创建时间", "update_time": "更新时间", "explains": "说明"
};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
// const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
const SelectBoolean = (<CustomSelect options={[{ value: 0, label: '否' }, { value: 1, label: '是' }]} />);
const formKeySelectComponentsObj = {
  // exampleColumn: SelectExample,
  'is_show': SelectBoolean,
};

//  ~~~~~~ 表单是图片列, 根据实际添加 ~~~~~~
const formKeysIsImages = ["icon", "img"];

//  ~~~~~~ 表单get 请求条件key, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = ["name"];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {
  "id": 0, "name": "", "front_desc": "", "front_name": "", "icon": "", "img": "", "is_category": 0,
  "is_channel": 1, "is_show": 1, "keywords": "", "level": "L1", "p_height": "155", "parent_id": 0,
  "show_index": 0, "sort_order": 1, "add_time": "", "update_time": "", "explains": ""
}

// ~~~~~~ 图片列名称, 根据实际修改 ~~~~~~
const imageKeyNames = ['icon', 'img'];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 1;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['channel-admin/v1/channels'];

// 修改 ~~~~~~~ item keys ~~~~~~;
const ItemRendererComponent = ({ item, index, itemDetailClick }) => {
  // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
  console.log(item);
  return (<div>
    <ListsItem
      listsIndex={index}
      url={item.icon_url}
      productTitle={item.name}
      productEffects={`频道简介: ${item.front_desc}`}
      salePrice={0}
      clickDetailHandle={itemDetailClick}
    ></ListsItem>
  </div>);
};

export {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum,
  apiLists,
  ItemRendererComponent
};
