import React, { useState, useEffect, useRef } from 'react';

import ProductsList from '../../base_components/products_list';
import { ChannelsRequestGet } from './channels_requests';
// import CreateChannels from './create_channels';
import UpdateChannels from './update_channels';
import { ItemRendererComponent } from './channels_columns_label_config';

// 列表编辑 页面

const ChannelsList = ({ reloadListCount, setReloadListCount, requestParams }) => {
  const hasMore = useRef(true);
  const [hasMorestate, setHasMoreState] = useState(true);
  const [loading, setLoading] = useState(false);
  const reqParams = useRef({ page: 0 });

  const [channelsData, setChannelsData] = useState([]);
  const [channelsRequestGetCount, setChannelsRequestGetCount] = useState(0);

  // const [createChannelsVisible, setCreateChannelsVisible] = useState(false);
  const [updateChannelsVisible, setUpdateChannelsVisible] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});

  useEffect(() => {
    // 加载、更新或创建数据后 重新读取列表
    reqParams.current = { ...requestParams };
    reloadListsHandle();
  }, [reloadListCount]);

  useEffect(() => {
    // useState才可以读取更多
    setHasMoreState(hasMore.current);
  }, [hasMore.current])

  const loadMore = () => {
    // 读取更多列表
    if (hasMore.current && !loading) {
      const current_page = Number.isInteger(reqParams.current.page) ? reqParams.current.page + 1 : 1;
      reqParams.current = { ...reqParams.current, page: current_page };
      console.log(reqParams.current);
      setChannelsRequestGetCount(channelsRequestGetCount + 1);
      setLoading(true);
    } else {
      console.log('Error state: hasMore is ', hasMore.current, ', loading is ', loading);
    }
  };

  const reloadListsHandle = () => {
    // 重新读取数据
    reqParams.current = { ...reqParams.current, page: 0 };
    // console.log(reqParams.current);
    setChannelsData([]);
    // setHasMore(true);
    hasMore.current = true;
    setLoading(false);
    setTimeout(() => {
      loadMore();
      console.log('end loadMore...');
    }, 100);
  };

  const judgeHasMore = resp => {
    if (Object.keys(resp).includes('next')
      && typeof resp.next === 'string'
      && resp.next.length > 5) {
      return true;
    }
    return false;
  };

  const channelsGetResponse = resp => {
    console.log('resp process...');
    setLoading(false);
    if (Object.keys(resp).includes('code')
      && resp.code === 1000
      && Object.keys(resp).includes('results')) {
      setChannelsData(channelsData.concat(resp.results));
      // setHasMore(judgeHasMore(resp));   // 判断是否还有更多
      hasMore.current = judgeHasMore(resp);
    } else {
      console.log('Error: get data...');
    }
  };

  const itemDetailClick = (index) => {
    // 打开详情编辑窗口
    // console.log(channelsData[index]);
    let selObj = { ...channelsData[index] };
    console.log(selObj);
    // 日期转 moment 时间
    // selObj.end_time = moment(dateFormat(selObj.end_time), 'YYYY-MM-DD HH:mm:ss');
    setSelectedObj(selObj);
    setUpdateChannelsVisible(true);
  };

  const CurrentItemRendererComponent = ({ item, index }) => {
    // 每一行渲染 的组件
    return (
      <ItemRendererComponent item={item} index={index} itemDetailClick={itemDetailClick} />
    )
  }

  return (
    <div>
      <ChannelsRequestGet requestCount={channelsRequestGetCount}
        reqParams={reqParams.current}
        responseDataHandle={channelsGetResponse}></ChannelsRequestGet>
      <ProductsList
        productsData={channelsData}
        loadMore={loadMore}
        hasMore={hasMorestate}
        ItemRendererComponent={CurrentItemRendererComponent}></ProductsList>

      <UpdateChannels
        visible={updateChannelsVisible}
        setVisible={setUpdateChannelsVisible}
        oldObj={selectedObj}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></UpdateChannels>
    </div>
  );
}

ChannelsList.defaultProps = {
  reloadListCount: 1,
  setReloadListCount: (addedNum) => { console.log(addedNum); },
  requestParams: { page: 0 }
}

export default ChannelsList;
