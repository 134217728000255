import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import ChannelsComponent from '../components/wx_store/channels';

const ChannelsManagePage = () => (
  <SiteLayout>
    <SEO title="WX Channels" />
    <ChannelsComponent></ChannelsComponent>
  </SiteLayout>
)

export default ChannelsManagePage;
